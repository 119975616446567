import { Controller } from "stimulus";

export default class extends Controller {
  static values = { input: String };

  static targets = ["container", "template"];

  connect() {
    this.toggle({ target: { value: this.inputValue } });
  }

  toggle = (e) => {
    let template = this.templateTargets.find((tmp) => tmp.id == e.target.value);
    this.containerTarget.innerHTML = template.innerHTML;
  };
}
