import { Controller } from "stimulus"

export default class extends Controller {
  togglePostalClasses(e) {
    document.getElementById("print-job-postal-classes")
            .classList
            .toggle("none", e.target.value !== "false");
  }

  selectFacility(e) {
    Turbo.visit(`/shop/print_jobs/new?print_facility_id=${e.target.value}`,  { action: "replace" });
  }
}
