import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["checkbox"];

  selectAll() {
    const checked = this.checkboxTargets.every((checkbox) => checkbox.checked);
    this.checkboxTargets.forEach((checkbox) => (checkbox.checked = !checked));
  }
}
